import React from "react";
import "./Header.scss";

export interface HeaderProps {
  id: string;
  children?: React.ReactNode;
  className?: string;
}

const Header = (props: HeaderProps) => {
  return (
    <header id={props.id} className={props.className}>
      {props.children}
    </header>
  );
};

export default Header;
