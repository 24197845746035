import React from "react";
import "./App.scss";
import { Footer, FormSubscribe, Header } from "./containers";
import logo from "./logo.png";
import { Logo } from "./components";

function App() {
  return (
    <div className="App center">
      <Header id="header">
        <Logo id="header-logo" src={logo} className="logo" alt="logo"></Logo>
      </Header>
      <FormSubscribe
        id="form-subscribe"
        name="form-subscribe"
        className="round center"></FormSubscribe>
      <Footer id="footer">
        <p className="footer-copyright">&copy; Copyright 2022 neverdonegames</p>
      </Footer>
    </div>
  );
}

export default App;
