import React from "react";
import "./Footer.scss";

export interface FooterProps {
  id: string;
  children?: React.ReactNode;
  className?: string;
}

const Footer = (props: FooterProps) => {
  return (
    <footer id={props.id} className={props.className}>
      {props.children}
    </footer>
  );
};

export default Footer;
