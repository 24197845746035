import React, { useState } from "react";
import { Button, Input } from "../../components";
import "./Subscribe.scss";
import axios from "axios";

export interface SubscribeProps {
  id: string;
  name: string;
  className?: string;
}

const Subscribe = (props: SubscribeProps) => {
  const [subscribed, setSubscribed] = useState(false);
  const [email, setEmail] = useState("");
  const showMsg = subscribed ? "show" : "hidden-fade";

  const handleChange = (e: any) => {
    setEmail(e.target.value);
  };

  const handleSubmit = async (e: any) => {
    e.preventDefault();
    if (!email) {
      return;
    }
    try {
      const { data } = await axios.post(
        "https://api.neverdonegames.com/subscribe",
        { email },
        {
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: "",
          },
        }
      );
      console.log(JSON.stringify(data, null, 4));
      if (data && data.statusCode === 200) {
        setSubscribed(true);
        setEmail("");
      }
    } catch (error) {
      if (axios.isAxiosError(error)) {
        console.log("error message: ", error.message);
        return error.message;
      } else {
        console.log("unexpected error: ", error);
        return "An unexpected error occurred";
      }
    }
  };

  return (
    <form
      id={props.id}
      name={props.name}
      className={props.className}
      onSubmit={handleSubmit}>
      <p>
        Please subscribe to receive news of our newest project "Death's
        Decisions"
      </p>
      <div className="round">
        <Input
          id="input-email"
          className="input"
          name="email"
          alt="email"
          type="email"
          value={email}
          required={true}
          onChange={handleChange}
          placeholder="email@domain.com"></Input>
      </div>
      <div className="rgb-border">
        <Button
          id="button-subscribe"
          className="button"
          name="btn_subscribe"
          label="subscribe"></Button>
      </div>
      <div className={showMsg} onTransitionEnd={() => setSubscribed(false)}>
        <p>Thanks for subscribing!</p>
      </div>
    </form>
  );
};

export default Subscribe;
