import React, { ReactEventHandler, ReactNode } from "react";
import "./Input.scss";

export interface InputProps {
  id: string;
  name: string;
  value: string;
  type: string;
  required?: boolean;
  children?: ReactNode;
  className?: string;
  alt?: string;
  placeholder?: string;
  min?: number;
  max?: number;
  onChange?: ReactEventHandler;
}

const Input = (props: InputProps) => {
  const minMaxCheck = ["number", "date"];
  const minMax = {
    min: props.min || undefined,
    max: props.max || undefined,
  };
  const minMaxLength = {
    minLength: props.min || undefined,
    maxLength: props.max || undefined,
  };
  const minMaxProp = minMaxCheck.includes(props.type) ? minMax : minMaxLength;
  return (
    <input
      className={props.className}
      type={props.type}
      name={props.name}
      alt={props.alt}
      placeholder={props.placeholder}
      {...minMaxProp}
      required={props.required}
      onChange={props.onChange}
      value={props.value}>
      {props.children}
    </input>
  );
};

export default Input;
