import React from "react";
import "./Logo.scss";

export interface LogoProps {
  id: string;
  src: string;
  children?: React.ReactNode;
  className?: string;
  alt?: string;
  placeholder?: string;
}

const Logo = (props: LogoProps) => {
  return (
    <img
      id={props.id}
      src={props.src}
      className={props.className}
      alt={props.alt}
      placeholder={props.placeholder}>
      {props.children}
    </img>
  );
};

export default Logo;
