import React from "react";
import ReactDOM from "react-dom";
import "./index.scss";
import App from "./App";
import { Helmet, HelmetProvider } from "react-helmet-async";

ReactDOM.render(
  <React.StrictMode>
    <HelmetProvider>
      <Helmet prioritizeSeoTags>
        <meta charSet="utf-8" />
        <title>Never Done Games</title>
        <link rel="canonical" href="https://neverdonegames.com" />
        <meta name="description" content="Never Done Games indie game studio" />
        <meta name="og:title" content="Never Done Games" />
      </Helmet>
      <App />
    </HelmetProvider>
  </React.StrictMode>,
  document.getElementById("root")
);
